import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Seo from '../../components/Seo'
import Layout from '../../layouts';
import CareersForm from './careersForm';
import map from '../../images/icons/map-pin-white.svg';

const Apply = (props) => {
  const [careerTitle, setCareerTitles] = useState([]);
  const [career, setCareer] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    const title = new URL(window.location.href).searchParams.get('title');
    setCareerTitles(title);
  }, []);

  // const [careers, setCareers] = useState([]);

  useEffect(async () => {
    try {
      const results = await axios.get(
        'https://analytics-internal.itelasoft.com.au/PublicData/JobOpenings',
        {
          headers: {
            'x-api-key': '6fa78d2c16d34c6aa31bd6073ae86b5b',
          },
        },
      );
      // setCareers(results.data);

      const id = new URL(window.location.href).searchParams.get('id');
      const career = results.data.find((c) => c.id === id);
      console.log(career);
      setCareer(career);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Layout bodyClass="page-apply">
      <Seo title="Apply" description="Join iTelSoft" />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.applyImg.childImageSharp.fluid}
            loading="eager"
            fadeIn
            alt="Banner Image"
          />
        </div>
        <div className="container">
          <div className="row main-page career-page">
            <div className="col-12">

              {careerTitle ? (
                <div className="career-page-title">Apply for {careerTitle} Position</div>
              ) : (
                <div className="career-page-title">Join iTelaSoft</div>
              )}

              <div className="page-subtitle">
                {career.location ? (
                  <div className="job-location">
                    <span>
                      <img className="map" src={map} />
                    </span>
                    <span className="loc-txt">{career.location.split(',').join(', ')}</span>
                  </div>) : (
                  <div className="d-none loc-txt"></div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" id="career-apply">
        <section className="apply-career-section">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 card-bg">
              {isLoading ? (
                <Spinner animation="border" className='cr-loader' role="status">
                  {/* <span className="visually-hidden">Loading...</span> */}
                  <div class="lds-dual-ring"></div>
                </Spinner>
              ) : (
                <div className="card-apply">
                  {/* <div className="job-title">{career.title}</div> */}
                  {/* <div className="job-location">
                    <div>
                      <img className="map" src={map} />
                    </div>
                    <div className="ml-1 loc-txt">{career.location.split(',').join(', ')}</div>
                  </div> */}
                  <div className="job-opportunity space-jb mb-4">
                    {career.description ? (
                      <div className="job-description">
                        <h5 className="title title-1 mb-2">The Opportunity</h5>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: career.description
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="job-coreSkills space-jb mb-4">
                    {career.coreSkills ? (
                      <div className="coreSkills">
                        <h5 className="title title-2">Skills You Need</h5>
                        <p>{career.coreSkills}</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="job-responsibilities space-jb mb-4">
                    {career.responsibilities ? (
                      <div className="responsibilities">
                        <h5 className="title title-2">You will Engage In</h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: career.responsibilities
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="job-qualifications space-jb mb-4">
                    {career.qualifications ? (
                      <div className="qualifications">
                        <h5 className="title title-2">What would Make You Stand Out</h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: career.qualifications
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}

              {/* <div>
  <h1>{career.id}</h1>
  <h1>{career.title}</h1>
  <h1>{career.location}</h1>

  <h1>{career.description ? (

    <div
      className="description"
      dangerouslySetInnerHTML={{
        __html: career.description,
      }}
    />

  ) : (
    ""
  )}</h1>

  <h1>{career.coreSkills ? (
    <div className="coreSkills">
      <h5>Skills You Need</h5>
      <p>{career.coreSkills}</p>
    </div>
  ) : (
    ""
  )}
  </h1>

  <h1>
    {career.responsibilities ? (
      <div className="responsibilities">
        <h5>You will Engage In</h5>
        <div
          dangerouslySetInnerHTML={{
            __html: career.responsibilities,
          }}
        />
      </div>
    ) : (
      ""
    )}
  </h1>

  <h1>
    {career.qualifications ? (
      <div className="qualifications">
        <h5>What would Make You Stand Out</h5>
        <div
          dangerouslySetInnerHTML={{
            __html: career.qualifications,
          }}
        />
      </div>
    ) : (
      ""
    )}
  </h1>
</div> */}
            </div>

            {!isLoading &&
              <div className="col-lg-5 col-md-8 col-sm-12 form-bg">
                {/* <form
                className="contact-form"
                action="https://lpfm7fiz56.execute-api.ap-southeast-2.amazonaws.com/default/itela_careers_function"
                method="post"
              >
                <div className="wr-form mb-xs-5">
                  <div className="form-group">
                    <div>
                      <label className="control-label">
                        <div>YOUR NAME (REQUIRED)</div>
                      </label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <label className="control-label">
                        <div>Position Applying for (REQUIRED)</div>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={careerTitle ? careerTitle : ""}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <label className="control-label">
                        <div>Contact No (REQUIRED)</div>
                      </label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <label className="control-label">
                        <div>Email (REQUIRED)</div>
                      </label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <label className="control-label">
                        <div>Attach Resume (REQUIRED)</div>
                      </label>
                      <input type="file" className="form-control" />
                    </div>
                  </div>

                  <div className="submit-button buttons text-center text-md-left">
                    <button className="btn btn-primary" type="submit">
                      Apply
                    </button>
                  </div>
                </div>
              </form> */}
                <CareersForm career={careerTitle} />
              </div>
            }
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    applyImg: file(relativePath: { eq: "apply.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Apply;
